import { ISubmissionFilters } from '@/interfaces/ISubmission'
import { ICustomer } from '@/interfaces/IUser'
import { atom } from 'jotai'

export const submissionActiveFilterURIAtom = atom('')
export const submissionUrlCache = atom('')

export const fetchedOrgUserAtom = atom('')
export const fetchedOrgUserResults = atom<ICustomer[]>([])
export const roadmapCurrentSubmissionParentFilterAtom = atom<ISubmissionFilters | undefined>(
  undefined
)
export const activeSubmissionIdsAtom = atom<string[]>([])
