import { ISubmissionFilters } from '@/interfaces/ISubmission'
import { cn } from '@/lib/utils'
import { PlusCircleIcon, XCircleIcon, XIcon } from '@heroicons/react/solid'
import React, { SetStateAction, useEffect, useMemo, useState } from 'react'
import ActiveViewItem from './GenerateActiveView'
import { CommandGroup } from './radix/Command'
import ModularComboBox from './radix/ModularComboBox'
import { DatePicker } from './radix/DatePicker'
import { format } from 'date-fns'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from './radix/DropdownMenu'
import { useTranslation } from 'next-i18next'
import { useCurrentOrganization } from '@/data/organization'
import { getIdentifiedUser } from 'network/lib/user'
import UserPicture from './UserPicture'
import ActiveUserSearchResults from './ActiveUserSearchResults'
import { ICustomer, ICustomerCompany } from '@/interfaces/IUser'
import { TooltipProvider } from './radix/Tooltip'
import { getCompanyById } from 'network/lib/organization'
import ActiveCompanySearchResults from './ActiveCompanySearchResults'

export const getOperatorDisplayValue = (t: any, operator: string) => {
  switch (operator) {
    case 'is':
      return t('is')
    // return 'Is'
    case 'is not':
      return t('is-not')
    case '!exists':
      return t('not-set')
    case 'gte':
      return t('after')
    case 'lte':
      return t('before')
  }
}

const FilterTabElement: React.FC<{
  filter: any
  index: number
  initialFilter: any
  roadmap: boolean
  setActiveFilters: (update: SetStateAction<ISubmissionFilters>) => void
  publicBoard?: boolean
  setCreateNewSegmentOpen?: React.Dispatch<React.SetStateAction<boolean>>
}> = ({
  filter,
  index,
  initialFilter,
  roadmap,
  setActiveFilters,
  publicBoard,
  setCreateNewSegmentOpen,
}) => {
  const { t } = useTranslation()
  const { org } = useCurrentOrganization()

  const [author, setAuthor] = useState<{
    id: string
    name: string
    profilePicture: string
  } | null>(null)

  const [company, setCompany] = useState<ICustomerCompany | null>(null)

  useEffect(() => {
    const fetchAuthorName = async () => {
      if (initialFilter.key === 'author' && filter.values.length > 0) {
        try {
          const res = await getIdentifiedUser({ id: filter.values[0] })
          setAuthor({
            id: res.data.user.fbUserId,
            name: res.data.user.name,
            profilePicture: res.data.user.profilePicture,
          })
        } catch (error) {
          console.error('Error fetching author name:', error)
          setAuthor(null)
        }
      } else if (initialFilter.key === 'company' && filter.values.length > 0) {
        try {
          const res = await getCompanyById(filter.values[0])
          setCompany(res.data.company)
        } catch (error) {
          console.error('Error fetching company name:', error)
          setCompany(null)
        }
      }
    }

    fetchAuthorName()
  }, [initialFilter.type, filter.values])

  const firstItemName = useMemo(
    () =>
      filter.values[0] && initialFilter?.getItemFromId
        ? initialFilter?.getItemFromId(filter.values[0])
        : '',
    [filter.values, initialFilter]
  )

  const updateActiveFilters = (currentValue: string) => {
    setActiveFilters((prev) => ({
      ...prev,
      advancedFilters: prev?.advancedFilters?.map((elem) => {
        if (elem.id === filter.id) {
          if (initialFilter.type === 'oneSelectable') {
            return {
              ...elem,
              values: [currentValue],
            }
          } else {
            return {
              ...elem,
              values: elem.values?.includes(currentValue)
                ? elem.values.filter((item: any) => item !== currentValue)
                : [...elem.values, currentValue],
            }
          }
        } else {
          return elem
        }
      }),
    }))
  }

  const changeOperator = (value: string) => {
    setActiveFilters((prev) => ({
      ...prev,
      advancedFilters: prev?.advancedFilters?.map((item: any) => {
        if (item.id === filter.id) {
          return {
            ...item,
            operator: value,
          }
        } else {
          return item
        }
      }),
    }))
  }

  const singleBooleanOption = initialFilter.name === 'Author only'

  return (
    <div
      key={filter.id}
      className={cn(
        'flex items-center text-xs font-medium  border rounded-md shadow-sm dark:shadow dark:bg-secondary'
      )}
    >
      <p className="flex items-center text-gray-400 dark:text-gray-100 gap-2 capitalize  px-2 py-1.5">
        {initialFilter && <initialFilter.icon className="w-4 h-4 secondary-svg" />}
        {t(initialFilter.key)}
      </p>
      {!['Stale'].includes(initialFilter.name) && (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <p className=" px-2 border-x text-gray-500 hover:bg-gray-50 dark:hover:bg-dark-accent/40 cursor-pointer main-transition dark:text-foreground dark:border-border py-1.5">
              {singleBooleanOption
                ? filter.values[0] === 'true'
                  ? 'Enabled'
                  : 'Disabled'
                : getOperatorDisplayValue(t, filter.operator)}
            </p>
          </DropdownMenuTrigger>
          <DropdownMenuContent
            className={initialFilter.type === 'date' ? 'w-40' : 'w-32'}
            align="start"
          >
            {initialFilter.type === 'date' ? (
              <>
                <DropdownMenuItem onSelect={() => changeOperator('lte')}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4 mr-1.5 secondary-svg"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    stroke="currentColor"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M5 18l14 -6l-14 -6" />
                  </svg>
                  {t('before')}
                </DropdownMenuItem>
                <DropdownMenuItem onSelect={() => changeOperator('gte')}>
                  {' '}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4 mr-1.5 secondary-svg"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    // stroke="#2c3e50"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path stroke="currentColor" d="M19 18l-14 -6l14 -6" />
                  </svg>
                  {t('after')}
                </DropdownMenuItem>
                {initialFilter.backendValue === 'e' ? (
                  <DropdownMenuItem onSelect={() => changeOperator('!exists')}>
                    <XCircleIcon className="w-4 h-4 mr-1.5 secondary-svg" />
                    {t('not-set')}
                  </DropdownMenuItem>
                ) : null}
              </>
            ) : (
              <>
                <DropdownMenuItem
                  onSelect={() => {
                    singleBooleanOption ? updateActiveFilters('true') : changeOperator('is')
                  }}
                >
                  <PlusCircleIcon className="w-4 h-4 mr-1.5 secondary-svg" />
                  {singleBooleanOption ? 'Enabled' : t('is')}
                </DropdownMenuItem>

                {initialFilter.backendValue !== 'segment' &&
                  initialFilter.backendValue !== 'stale' && (
                    <DropdownMenuItem
                      onSelect={() => {
                        singleBooleanOption
                          ? updateActiveFilters('false')
                          : changeOperator('is not')
                      }}
                    >
                      <XIcon className="w-4 h-4 mr-1.5 secondary-svg" />
                      {singleBooleanOption ? 'Disabled' : t('is-not')}
                    </DropdownMenuItem>
                  )}
                {initialFilter.backendValue === 't' || initialFilter.backendValue === 'e' ? (
                  <DropdownMenuItem onSelect={() => changeOperator('!exists')}>
                    <XCircleIcon className="w-4 h-4 mr-1.5 secondary-svg" />
                    {t('not-set')}
                  </DropdownMenuItem>
                ) : null}
              </>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      )}

      {initialFilter.name !== 'Stale' &&
        initialFilter.name !== 'Author only' &&
        filter.operator !== '!exists' && (
          <div className="border-r dark:border-border">
            {initialFilter.type === 'date' ? (
              <DatePicker
                showQuarterButtons={true}
                selected={filter?.values?.[0] ? (new Date(filter?.values[0]) as Date) : undefined}
                onSelect={(date) =>
                  setActiveFilters((prev) => ({
                    ...prev,
                    advancedFilters: prev?.advancedFilters?.map((item: any) => {
                      if (item.id === filter.id) {
                        return {
                          ...item,
                          values: [date.toISOString()],
                        }
                      } else {
                        return item
                      }
                    }),
                  }))
                }
                CustomButton={
                  <p className="py-1.5 px-2 text-xs text-gray-500 hover:bg-gray-50 dark:hover:bg-dark-accent/40 cursor-pointer main-transition dark:text-foreground dark:border-border">
                    {filter.values?.[0]
                      ? format(new Date(filter.values[0]), 'MMM dd, yyyy').toString()
                      : 'Not selected'}
                  </p>
                }
              />
            ) : initialFilter.key === 'author' ? (
              <TooltipProvider>
                <ModularComboBox
                  popoverContentProps={{
                    align: 'start',
                  }}
                  TriggerButton={() => (
                    <p className="py-1.5 flex items-center px-2 text-xs text-gray-500 hover:bg-gray-50 dark:hover:bg-dark-accent/40 cursor-pointer main-transition dark:text-foreground dark:border-border">
                      <UserPicture
                        authorId={author?.id}
                        xSmall={true}
                        img={author?.profilePicture}
                      />
                      <p className="ml-2 select-none max-w-[350px] truncate font-medium dark:font-semibold">
                        {author?.name ? author?.name : 'An Anonymous User'}
                      </p>
                    </p>
                  )}
                  CommandItems={({ closeComboBox }) => {
                    return (
                      <CommandGroup>
                        <ActiveUserSearchResults
                          setNewAuthor={(user?: ICustomer) => {
                            closeComboBox()
                            if (user) {
                              updateActiveFilters(user.id)
                            }
                          }}
                        />
                      </CommandGroup>
                    )
                  }}
                  allowNewCreation={false}
                  searchableDisplayName="users"
                />
              </TooltipProvider>
            ) : initialFilter.key === 'company' ? (
              <TooltipProvider>
                <ModularComboBox
                  popoverContentProps={{
                    align: 'start',
                  }}
                  TriggerButton={() => (
                    // <button className="flex -my-1.5 p-1.5 hover:dark:bg-border hover:bg-gray-50 items-center col-span-3 truncate">
                    //   <p className="font-medium truncate dark:font-semibold">
                    //     {company?.name ? company?.name : 'An Unknown Company'}
                    //   </p>
                    // </button>
                    <p className="select-none px-2 dark:hover:bg-dark-accent/40  hover:bg-gray-50 cursor-pointer main-transition dark:text-foreground dark:border-border py-1.5">
                      {company?.name ? company?.name : 'An Unknown Company'}
                    </p>
                  )}
                  CommandItems={({ closeComboBox }) => {
                    return (
                      <CommandGroup>
                        <ActiveCompanySearchResults
                          onSelectCompany={(company?: ICustomerCompany) => {
                            closeComboBox()
                            if (company) {
                              updateActiveFilters(company.id)
                            }
                          }}
                        />
                      </CommandGroup>
                    )
                  }}
                  allowNewCreation={false}
                  searchableDisplayName="companies"
                />
              </TooltipProvider>
            ) : (
              <ModularComboBox
                TriggerButton={() => (
                  <p className=" px-2 dark:hover:bg-dark-accent/40  hover:bg-gray-50 cursor-pointer main-transition dark:text-foreground dark:border-border py-1.5">
                    {firstItemName && initialFilter?.getItemName
                      ? initialFilter?.getItemName(firstItemName as any)
                      : 'Not selected'}
                    {filter.values.length > 1 ? <> (+{filter.values.length - 1})</> : null}
                  </p>
                )}
                allowNewCreation={false}
                searchableDisplayName="filter"
                popoverContentProps={{
                  align: roadmap ? 'end' : 'start',
                }}
                CommandItems={({ closeComboBox, setOnlyDisplayCustomPage }) => {
                  return (
                    <CommandGroup>
                      {initialFilter.options?.map((option: any, index: number) => {
                        return (
                          <ActiveViewItem
                            addFilter={(currentValue: string) => updateActiveFilters(currentValue)}
                            index={index}
                            item={initialFilter}
                            initialChecked={(() => {
                              if (filter.type === 'stale') {
                                return filter.values?.includes(option) as boolean
                              } else {
                                return filter.values?.includes(
                                  option[initialFilter.optionIdKey]
                                ) as boolean
                              }
                            })()}
                            option={option}
                            setCreateNewSegmentOpen={setCreateNewSegmentOpen}
                            setOpen={() => closeComboBox()}
                            key={
                              initialFilter.optionIdKey ? option[initialFilter.optionIdKey] : index
                            }
                          />
                        )
                      })}
                    </CommandGroup>
                  )
                }}
              />
            )}
          </div>
        )}
      <button
        onClick={() => {
          setActiveFilters((prev) => {
            const newAdvancedFilters = prev.advancedFilters.filter(
              (elem: any) => elem.id !== filter.id
            )
            let foundHiddenOrCompletedFilter = false

            if (!publicBoard)
              newAdvancedFilters.forEach((filter) => {
                if (filter.type === 's') {
                  if (
                    filter.values.some((value: any) => {
                      const foundType = org?.postStatuses?.find((status) => status.id === value)
                        ?.type
                      return foundType === 'completed' || foundType === 'canceled'
                    })
                  ) {
                    foundHiddenOrCompletedFilter = true
                  }
                }
              })

            return {
              ...prev,
              advancedFilters: prev.advancedFilters.filter((elem: any) => elem.id !== filter.id),
            }
          })
        }}
        className="px-2 py-2 cursor-pointer rounded-r-md hover:bg-gray-50 dark:hover:bg-dark-accent/40 main-transition dark:text-foreground unstyled-button"
      >
        <XIcon className="w-3 h-3 secondary-svg" />
      </button>
    </div>
  )
}

export default FilterTabElement

export const FilterItems = ({ closeComboBox, initialFilter, updateActiveFilters, filter }: any) =>
  React.useMemo(() => {
    return (
      <CommandGroup>
        {initialFilter.options?.map((option: any, index: number) => {
          return (
            <ActiveViewItem
              addFilter={(currentValue: string) => updateActiveFilters(currentValue)}
              index={index}
              item={initialFilter}
              initialChecked={(() => {
                if (filter.type === 'stale' || filter.name === 'Author only') {
                  return filter.values?.includes(option) as boolean
                } else {
                  return filter.values?.includes(option[initialFilter.optionIdKey]) as boolean
                }
              })()}
              option={option}
              setOpen={() => closeComboBox()}
              key={initialFilter.optionIdKey ? option[initialFilter.optionIdKey] : index}
            />
          )
        })}
      </CommandGroup>
    )
  }, [])
