import { Tag } from '../../src/components/NewEditModal'
import { IOrganizationStatus } from '../../src/interfaces/IOrganization'
import axiosClient from '../apiClient'

export function createSubmission(data: {
  title: string
  content: string
  category: { category: string; private: boolean }
  tags?: Tag[]
  commentsAllowed?: boolean
  postStatus?: IOrganizationStatus
  eta?: string | undefined
  metadata: object | undefined
  email?: string
  authorId?: string
  assignee?: string | null
  customInputValues?: object
}) {
  return axiosClient.post('/v1/submission', data)
}

export function getSingleSubmissionWithoutSWR(id: string) {
  return axiosClient.get('/v1/submission', { params: { id, includeMergedPosts: true } })
}

export function getSubmissionSuggestedCategory(input: string) {
  return axiosClient.get('/v1/submission/getTextCategory', { params: { input } })
}

export function getSurveyVoters(id: string, type: string) {
  return axiosClient.get('/v1/organization/submissionSurveyVoters', {
    params: { submissionId: id, type },
  })
}

export function getCommentVoters(id: string) {
  return axiosClient.get('/v1/comment/commentVoters', {
    params: { commentId: id },
  })
}

export function updateSubmission(data: {
  content?: string
  title?: string
  commentsAllowed?: boolean
  submissionId: string
}) {
  return axiosClient.patch('/v1/submission', data)
}

export function postComment(data: {
  content: string
  submissionId?: string
  changelogId?: string
  parentCommentId?: string
  isPrivate?: boolean
  sendNotification?: boolean
}) {
  return axiosClient.post('/v1/comment', data)
}

export function mergeSubmission(data: {
  mergeParentId: string
  submissionId?: string
  submissionIds?: string[]
}) {
  return axiosClient.post('/v1/submission/merge', data)
}
export function unLinkClickupTask(data: { submissionId: string; taskId: string }) {
  return axiosClient.post('/v1/organization/clickup/unlink', data)
}
export function unlinkGithubIssue(data: { submissionId: string; issueId: string }) {
  return axiosClient.post('/v1/organization/github/unlink', data)
}

export function voteOnComment(data: { commentId: string; type: string }) {
  return axiosClient.post('/v1/comment/vote', data)
}

export function deleteComment(id: string) {
  return axiosClient.delete('/v1/comment', { data: { id } })
}

export function editComment(data: {
  id: string
  content?: string
  isPrivate?: boolean
  submissionId?: string
  changelogId?: string
  pinned?: boolean
  inReview?: boolean
}) {
  return axiosClient.patch('/v1/comment', data)
}

export function updateSubmissionInfo(data: {
  submissionId?: string
  status?: string
  commentsAllowed?: boolean
  category?: { category: string; private: boolean }
  postTags?: Tag[]
  inReview?: boolean
  eta?: Date | string | null
  submissionIds?: string[]
  postStatus?: IOrganizationStatus
  notifyVoters?: boolean
  assignee?: string | null
  pinned?: boolean
  valueRating?: number
  effortRating?: number
  author?: {
    _id?: string
    name?: string
    email?: string
    type: 'admin' | 'customer'
  }
  access?: {
    add?: { _id: string; type: 'admin' | 'customer' }[]
    remove?: { _id: string; type: 'admin' | 'customer' }[]
  }
  customInputValues?: object
}) {
  const url = `/v1/submission/info?_=${new Date().getTime()}`
  return axiosClient.post(url, data)
  // return axiosClient.post('/v1/submission/info', data)
}

export function addUserRole(data: { roles: string[]; email: string }) {
  return axiosClient.post('/v1/organization/roles/addUserRole', data)
}

export function submissionStatusChangedNotification(data: {
  submissionId: string
  statusId: string
  statusUpdateComment?: string
}) {
  return axiosClient.post('/v1/submission/sendSubmissionStatusNotification', data)
}

export function removeSubmission(id: string) {
  return axiosClient.delete('/v1/submission', { data: { submissionId: id } })
}

export function removeSubmissions(ids: string[]) {
  return axiosClient.delete('/v1/submission', { data: { submissionIds: ids } })
}

export function upvote(submissionId: string, token?: string) {
  return axiosClient.post(
    '/v1/submission/upvote',
    { submissionId },
    {
      headers: token
        ? {
            'x-csrf-token': token,
          }
        : {},
    }
  )
}
export function downvote(submissionId: string, token?: string) {
  return axiosClient.post(
    '/v1/submission/downvote',
    { submissionId },
    {
      headers: token
        ? {
            'x-csrf-token': token,
          }
        : {},
    }
  )
}

export function addUpvoter(
  submissionId: string,
  type: 'admin' | 'customer' | 'guest',
  name: string,
  email?: string
) {
  return axiosClient.post('/v1/submission/addUpvoter', { submissionId, name, email, type })
}

export function removeUpvoter(
  submissionId: string,
  upvoter: { id?: string; email?: string; type: 'admin' | 'customer' | 'guest' }
) {
  return axiosClient.post('/v1/submission/deleteUpvoter', {
    submissionId,
    ...(upvoter.id && { upvoterId: upvoter.id }),
    ...(upvoter.email && { upvoterEmail: upvoter.email }),
    ...(upvoter.type && { type: upvoter.type }),
  })
}

export function subscribeWithEmail(submissionId: string, email: string) {
  return axiosClient.post('/v1/submission/subscribeWithEmail', { email, submissionId })
}

export function rateSubmission(submissionId: string, rating: number) {
  return axiosClient.post('/v1/submission/rate', { rating, submissionId })
}

export function getSubmissionsByPrioritization() {
  return axiosClient.get('/v1/submission/prioritization/valueEffort')
}
