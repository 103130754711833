import { IOrganization } from '@/interfaces/IOrganization'
import { IAdmin, ICustomer } from '@/interfaces/IUser'
import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'
import { sanitizeHTML } from './contentSanitizer'
import { defaultFetcher } from 'network/apiClient'
import { KeyedMutator, SWRConfiguration } from 'swr/dist/types'
import { QueryEntry } from '@/components/MainFilterDropdown'
import { v4 as uuid } from 'uuid'
import { ISubmission, ISubmissionFilters, ISubmissionPaginate } from '@/interfaces/ISubmission'
import { getFilterValue } from '@/pages'
import { GetServerSidePropsContext } from 'next'
import { SetStateAction } from 'react'
import { performSubmissionMutation } from './submissionMutator'
import { updateSubmissionInfo } from 'network/lib/submission'
import { toast } from 'sonner'
import { IntercomProps } from 'react-use-intercom'
import { isMember } from './acl'
import { z } from 'zod'
import { getEndDate, getStartDate } from '@/components/DedicatedRoadmapField'
import { Editor } from '@tiptap/react'
import isURL from 'validator/lib/isURL'
import { Node } from '@tiptap/pm/model'
import { useCallback, useState } from 'react'
import {
  IHelpCenterArticleDocument,
  IHelpCenterCollectionDocument,
  IHelpCenterDocument,
  IHelpCenterIcon,
} from '@/interfaces/IHelpCenter'
import { createHelpdocs, createHelpdocsCollection } from 'network/lib/helpcenter'
import { utcToZonedTime } from 'date-fns-tz'
import { parseISO } from 'date-fns'
import { getBoardUrl } from '@/pages/widget/changelogPopup'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { availableLocales } from './localizationUtils'
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function isPlan(currentPlan: string, mustBePlan: string) {
  const planOrder = ['free', 'trial', 'pro', 'growth', 'premium', 'enterprise']
  // Treat 'pro_lifetime' as 'pro' for comparison purposes.
  if (currentPlan === 'pro_lifetime') {
    currentPlan = 'pro'
  }
  if (mustBePlan === 'pro_lifetime') {
    mustBePlan = 'pro'
  }
  const currentPlanIndex = planOrder.indexOf(currentPlan)
  const mustBePlanIndex = planOrder.indexOf(mustBePlan)
  if (currentPlanIndex === -1 || mustBePlanIndex === -1) {
    return false
  }
  return currentPlanIndex >= mustBePlanIndex
}

export function getPlanName(plan: string) {
  plan = plan?.toLowerCase()
  if (plan === 'pro') return 'Starter'
  if (plan === 'pro_lifetime') return 'Starter'
  if (plan === 'growth') return 'Growth'
  if (plan === 'premium') return 'Business'
  return plan
}

export const defaultSWRConfig: SWRConfiguration = {
  fetcher: defaultFetcher,
  onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
    // Never retry on 404.
    if (error.status === 404) return

    // Only retry up to 5 times.
    if (retryCount >= 5) return

    // Increase retry delay exponentially.
    setTimeout(
      () => {
        revalidate({ retryCount })
      },
      Math.pow(2, retryCount) * 1000
    )
  },
  errorRetryInterval: 5000,
  shouldRetryOnError: false,
}

export const htmlContentParser = (content: string): string => {
  let modifiedParsedContent = sanitizeHTML(content)
  if (modifiedParsedContent) {
    modifiedParsedContent = modifiedParsedContent
      .replace(/<a.*?href="(.*?)".*?>(.*?)<\/a>/g, (match, src, text) => {
        let sanitizedText = sanitizeHTML(text)
        return `<a href="${src}" class="text-indigo-500 dark:text-indigo-300" target="_blank" rel="noreferrer">${sanitizedText}</a>`
      })
      .replace(/<iframe.*?src="(.*?)".*?<\/iframe>/g, (match, src) => {
        return `<iframe src="${src}" class="w-full h-96 rounded-xl shadow"></iframe>`
      })
  }
  return modifiedParsedContent || ''
}

// Define allowed parameters for the query
export const ALLOWED_PARAMS = [
  'sortBy',
  'inReview',
  'b', // 'board' / 'category'
  's', // 'status'
  'q', // 'query'
  'a', // 'assignee'
  't', // 'tag'
  'segment',
  'e', // 'eta'
  'd', // Date
  'stale',
  'authorOnly',
  'u', // Author id
  'c', // company id
  'view',
]

// Function to convert a Mongoose query string to an array of QueryEntry objects for frontend use
export function mongooseQueryStringToObject(queryString: string): QueryEntry[] {
  // Return an empty array if the queryString is 'undefined'
  if (queryString === 'undefined') {
    return []
  }

  const SKIP_PARAMS = ['mode']

  // Mapping string representations of operators to their logical equivalents
  const OPERATOR_MAP: Record<string, string> = {
    '!=': 'is not',
    '>=': 'gte',
    '<=': 'lte',
    '=': 'is',
    '>': 'gt',
    '<': 'lt',
  }

  // Sort the operators by length in descending order for correct identification in the string
  const sortedOperators = Object.keys(OPERATOR_MAP).sort((a, b) => b.length - a.length)

  // Initialize an array to store the converted query entries
  const entries: QueryEntry[] = []

  // Split the queryString into individual conditions
  const conditions = queryString?.split('&') || []

  for (const condition of conditions) {
    let operator: string = ''
    let type: string = ''
    let values: string[] = []

    // Skip the condition if it's in SKIP_PARAMS
    if (SKIP_PARAMS.some((param) => condition.startsWith(`${param}=`))) {
      continue
    }

    // Handling the special '!exists' operator
    if (condition.startsWith('!')) {
      operator = '!exists'
      type = condition.slice(1)
      entries.push({ type, operator, values, id: uuid() })
      continue
    }

    // Searching for operators in the condition
    let foundOperator = false
    for (const op of sortedOperators) {
      if (condition.includes(op)) {
        operator = OPERATOR_MAP[op] // Map the string operator to its logical equivalent

        // Extract the type and values from the condition
        type = decodeURIComponent(condition.substring(0, condition.indexOf(op)))

        // Continue if the type is not allowed
        if (!ALLOWED_PARAMS.includes(type)) {
          continue
        }

        // Extract and decode values after the operator
        values = condition
          .substring(condition.indexOf(op) + op.length)
          .split(',')
          .map(decodeURIComponent)

        entries.push({ type, operator, values, id: uuid() }) // Push the entry with a unique id
        foundOperator = true
        break
      }
    }

    // Continue to the next condition if an operator was found
    if (!foundOperator) {
      continue
    }

    // Handle the 'exists' operator if no other operator was found
    if (!operator) {
      operator = 'exists'
      type = condition
      entries.push({ type, operator, values, id: uuid() })
    }
  }

  // Return the array of QueryEntry objects
  return entries
}

// Function to convert query objects to Mongoose query strings for backend and frontend
// Frontend query is usually trimmed down to not show default filters
export function objectToMongooseQueryString(
  queryObj: QueryEntry[],
  filters: any,
  defaultFilters?: ISubmissionFilters,
  org?: IOrganization,
  hideCompletedAndCanceled?: boolean,
  roadmap?: boolean
): { backendQuery: string; frontendQuery: string } {
  // Mapping supported logical operators to their string equivalents in query
  const OPERATOR_MAP: Record<string, string> = {
    is: '=',
    gt: '>',
    gte: '>=',
    lt: '<',
    lte: '<=',
    ne: '!=',
    in: '=',
    'is not': '!=',
    exists: '',
    '!exists': '!',
  }

  // Grouping query entries by type and operator
  const grouped: Record<string, { type: string; operator: string; values: string[] }> = {}

  // If roadmap is true, include advanced filters from defaultFilters for backend query
  const backendQueryObj = queryObj

  backendQueryObj.forEach((entry) => {
    const key = `${entry.type}${OPERATOR_MAP[entry.operator]}`

    // Handling user-generated entries and hiding completed/canceled items based on organization settings
    if (
      !grouped[key] ||
      (entry.userGenerated && org?.settings.hideCompletedAndCanceled && entry.type === 's')
    ) {
      grouped[key] = { ...entry, values: [...entry.values] }
    } else {
      grouped[key].values.push(...entry.values)
    }
  })

  // Initializing array to hold parts of the query string
  const queryStringParts: string[] = []

  // Building query string parts from the grouped entries
  Object.values(grouped).forEach((entry) => {
    // Only proceed if the entry has values
    if (entry.operator in OPERATOR_MAP) {
      // Constructing query string part based on the operator type
      let currentPart = ''
      if (entry.operator === 'is' || entry.operator === 'is not') {
        currentPart = `${entry.type}${OPERATOR_MAP[entry.operator]}${entry.values.join(',')}`
      } else if (entry.operator === 'exists') {
        currentPart = `${entry.type}`
      } else if (entry.operator === '!exists') {
        currentPart = `!${entry.type}`
      } else {
        currentPart = `${entry.type}${OPERATOR_MAP[entry.operator]}${entry.values[0]}`
      }

      // Check for duplicates before pushing
      if (!queryStringParts.includes(currentPart)) {
        queryStringParts.push(currentPart)
      }
    }
  })

  // Find index of existing 'sortBy' in queryStringParts
  const sortByIndex = queryStringParts.findIndex((part) => part.startsWith('sortBy='))

  // If 'sortBy' exists and is different, replace it
  if (
    sortByIndex !== -1 &&
    filters.sortBy &&
    queryStringParts[sortByIndex] !== 'sortBy=' + filters.sortBy
  ) {
    queryStringParts[sortByIndex] = 'sortBy=' + filters.sortBy
  }
  // If 'sortBy' doesn't exist, add it
  else if (sortByIndex === -1 && filters.sortBy) {
    queryStringParts.push('sortBy=' + filters.sortBy)
  }

  // Handling 'inReview' and 'includePinned' filters
  if (filters.inReview && !queryStringParts.includes('inReview=true')) {
    queryStringParts.push('inReview=true')
  } else if (!filters.inReview && queryStringParts.includes('inReview=true')) {
    queryStringParts.splice(queryStringParts.indexOf('inReview=true'), 1)
  } else if (!filters.inReview && !filters.q) {
    queryStringParts.push('inReview=false')
  }

  if (filters.includePinned && !queryStringParts.includes('includePinned=true') && !filters.q) {
    queryStringParts.push('includePinned=true')
  } else if (!filters.includePinned && queryStringParts.includes('includePinned=true')) {
    queryStringParts.splice(queryStringParts.indexOf('includePinned=true'), 1)
  }

  // Handling search query filter 'q'
  if (filters.q && !queryStringParts.includes('q=' + filters.q)) {
    queryStringParts.push('q=' + filters.q)
  } else if (!filters.q && queryStringParts.some((part) => /^q=/.test(part))) {
    const indexToRemove = queryStringParts.findIndex((part) => /^q=/.test(part))
    if (indexToRemove !== -1) {
      queryStringParts.splice(indexToRemove, 1)
    }
  }

  // Handling hiding of completed and canceled statuses
  const defaultlyHiddenStatuses =
    hideCompletedAndCanceled && !filters.q
      ? org?.postStatuses
          ?.filter((status) => status.type === 'canceled' || status.type === 'completed')
          .map((status) => {
            return status.id
          })
      : []

  // Check if there are any 's=' or 's!=' parts in the query string
  const queryStringPartsHasUserSetStatuses = queryStringParts.some(
    (part) => /^s=/.test(part) || /^s!=/.test(part)
  )

  const backendModifiedQuery =
    hideCompletedAndCanceled && !queryStringPartsHasUserSetStatuses
      ? [...queryStringParts, `s!=${defaultlyHiddenStatuses?.join(',')}`]
      : queryStringParts

  if (defaultFilters) {
    const frontEndModifiedQuery = queryStringParts.filter((filter) => {
      if (filter.includes('sortBy=')) {
        if (defaultFilters.sortBy === filter.split('=')[1]) {
          return false
        } else {
          return true
        }
      } else if (filter.includes('includePinned=')) {
        return false
      } else if (filter.includes('inReview=false')) {
        return false
      } else if (filter.includes('inReview=true') && defaultFilters.inReview) {
        return false
      } else if (
        // This should only apply for default roadmap filters
        roadmap &&
        org &&
        getRoadmapDefaultFilters(org).advancedFilters.some((af) =>
          filter.startsWith(`${af.type}${OPERATOR_MAP[af.operator]}`)
        )
      ) {
        return false
      } else {
        return true
      }
    })
    return {
      backendQuery: backendModifiedQuery.join('&'),
      frontendQuery: frontEndModifiedQuery.join('&'),
    }
  }

  return {
    backendQuery: queryStringParts.join('&'),
    frontendQuery: queryStringParts.join('&'),
  }
}

export const getDefaultFilters = (org: IOrganization, includePinned = true): ISubmissionFilters => {
  return {
    sortBy: getFilterValue(org?.settings?.defaultSortingOrder || ''),
    advancedFilters: [],
    includePinned: includePinned,
    inReview: undefined,
    q: undefined,
  } as ISubmissionFilters
}

export const getRoadmapDefaultFilters = (
  org: IOrganization,
  customStatus?: string,
  otherFilters?: QueryEntry[]
): ISubmissionFilters => {
  // Filter and map boards (categories)
  const boards = org?.structure?.roadmap?.hiddenCategories?.map(
    (cat) => org?.postCategories?.find((c) => c.category === cat)?.id || ''
  )

  // Create default advancedFilters array
  const advancedFilters = []

  // Add boards filter if boards exist
  if (boards && boards.length > 0) {
    advancedFilters.push({
      type: 'b',
      operator: 'is not',
      id: uuid(),
      values: boards ? [...boards] : [],
    })
  }

  if (otherFilters) {
    advancedFilters.push(...otherFilters)
  }

  // Add postStatus filter only if customStatus is defined
  if (customStatus) {
    const postStatus = org?.postStatuses.find((status) => status.name === customStatus)?.id
    if (postStatus) {
      advancedFilters.push({
        type: 's',
        operator: 'is',
        id: uuid(),
        values: [postStatus],
      })
    }
  }

  return {
    sortBy: 'upvotes:desc',
    advancedFilters,
    inReview: undefined,
    includePinned: true,
    q: undefined,
    limit: 10,
  } as ISubmissionFilters
}

export const convertUuid = (initialUuid: string | number) => {
  if (typeof initialUuid === 'string') {
    const buffer = Buffer.from(initialUuid.replace(/-/g, ''), 'hex')
    const number = buffer.readUIntBE(0, buffer.length)
    return number
  } else {
    const buffer = Buffer.alloc(16)
    buffer.writeUIntBE(initialUuid, 0, 16)
    const uuid = [
      buffer.toString('hex', 0, 4),
      buffer.toString('hex', 4, 6),
      buffer.toString('hex', 6, 8),
      buffer.toString('hex', 8, 10),
      buffer.toString('hex', 10, 16),
    ].join('-')
    return uuid
  }
}

export const handleCheckboxClick = (
  id: string,
  event: React.MouseEvent<HTMLInputElement>,
  lastChecked: string | null,
  submissionResults: ISubmission[] | undefined,
  checkboxes: {
    [key: string]: boolean
  },
  setCheckboxes: (
    value: React.SetStateAction<{
      [key: string]: boolean
    }>
  ) => void,
  setLastChecked: React.Dispatch<React.SetStateAction<string | null>>
) => {
  if (!submissionResults) return
  if (event.shiftKey && lastChecked != null) {
    const start = submissionResults.findIndex((option) => option.id === (lastChecked || id))
    const end = submissionResults.findIndex((option) => option.id === id)
    const newCheckboxes = { ...checkboxes }
    for (let i = Math.min(start, end); i <= Math.max(start, end); i++) {
      const optionId = submissionResults[i].id
      if (lastChecked === submissionResults[i].id && !checkboxes[optionId]) continue
      if (lastChecked === submissionResults[i].id && checkboxes[optionId]) continue
      if (checkboxes[optionId]) {
        newCheckboxes[optionId] = false
      } else {
        newCheckboxes[optionId] = true
      }
    }
    setCheckboxes(newCheckboxes)
  } else {
    setCheckboxes({ ...checkboxes, [id]: !checkboxes[id] })
  }
  setLastChecked(id)
}

export function isValidObjectId(str: string): boolean {
  // Check if the string is exactly 24 characters long
  if (!str || (str && str?.length !== 24)) {
    return false
  }

  // Check if all characters are hexadecimal
  const hexRegex = /^[0-9a-fA-F]{24}$/
  return hexRegex.test(str)
}

export const isLocalStorageAvailable = () => {
  const test = 'test'
  try {
    localStorage?.setItem(test, test)
    localStorage?.removeItem(test)
    return true
  } catch (e) {
    return false
  }
}

export const determineSSRTheme = (org: IOrganization, context: GetServerSidePropsContext) => {
  let defaultTheme = org?.settings?.defaultTheme || 'client'

  if (context.query?.theme === 'light' || context.query?.theme === 'dark') {
    defaultTheme = context.query?.theme
  }

  return defaultTheme
}

export const metadataSchema = z.record(z.string()).nullable()

export const parseMetadataJson = (metadata: string) => {
  try {
    // Validate the metadata schema before parsing
    const parsedMetadata = JSON.parse(metadata)
    metadataSchema.parse(parsedMetadata)

    return parsedMetadata
  } catch (e) {
    console.log('Error parsing metadata JSON:', e)
    return {}
  }
}

// Example usage:
// Assuming you have an array 'changelogCategories' with a property 'name' you want to sort by:
// const sortedCategories = sortArrayByProperty(changelog?.changelogCategories, 'name');
export function sortChangelogCategories(array: { id: string; name: string; roles?: string[] }[]) {
  // Make a copy of the array to avoid mutating the original array
  const items = [...array]

  // Separate sorting logic
  const sortByName = (a: { name: string }, b: { name: string }) => {
    return a.name.localeCompare(b.name)
  }

  // Sort the array
  return items.sort(sortByName)
}

export const handleMentionKeyDown = (
  event: KeyboardEvent,
  totalCount: number,
  setHoverIndex: (value: SetStateAction<number>) => void,
  handleCommand: (index: number) => void,
  hoverIndex: number
) => {
  const { key } = event

  if (key === 'ArrowUp') {
    setHoverIndex((prev) => {
      const beforeIndex = prev - 1
      return beforeIndex >= 0 ? beforeIndex : 0
    })
    return true
  }

  if (key === 'ArrowDown') {
    setHoverIndex((prev) => {
      const afterIndex = prev + 1
      return afterIndex < totalCount ? afterIndex : totalCount - 1
    })
    return true
  }

  if (key === 'Enter') {
    handleCommand(hoverIndex)
    return true
  }

  return false
}

export const keepPostBetweenAuthor = (
  submissionIds: string[],
  authors: { _id: string; type: 'admin' | 'customer' }[],
  mutateSubmissions: KeyedMutator<any>,
  rawSubmissionData: ISubmissionPaginate | ISubmissionPaginate[] | undefined,
  mutateBoth?: boolean,
  changeInReview?: boolean
) => {
  updateSubmissionInfo({
    submissionIds,
    access: {
      add: authors,
    },
    ...(changeInReview && { inReview: false }),
  })
    .then(() => {
      toast.success('The post is now only visible to the author')
    })
    .catch(() => {
      toast.error('Could not change post privacy')
    })
  // IS array
  if (mutateBoth && Array.isArray(rawSubmissionData)) {
    mutateSubmissions(
      rawSubmissionData?.map((entry: any) => ({
        ...entry,
        results: entry.results.map((submission: ISubmission) => ({
          ...submission,
          // Check if author already is in the submission and dont add it again
          accessUsers: submissionIds.includes(submission.id)
            ? [
                ...(submission?.accessUsers || []),
                ...authors.filter(
                  (author) =>
                    !submission?.accessUsers?.some(
                      (existingUser) =>
                        existingUser._id === author._id && existingUser.type === author.type
                    )
                ),
              ]
            : submission?.accessUsers,
          inReview:
            submissionIds.includes(submission.id) && changeInReview ? false : submission?.inReview,
        })),
      })),
      false
    )
  } else {
    performSubmissionMutation(
      mutateSubmissions,
      (oldResults) =>
        oldResults.map((submission) => ({
          ...submission,
          // Check if author already is in the submission and dont add it again
          accessUsers: submissionIds.includes(submission.id)
            ? [
                ...(submission?.accessUsers || []),
                ...authors.filter(
                  (author) =>
                    !submission?.accessUsers?.some(
                      (existingUser) =>
                        existingUser._id === author._id && existingUser.type === author.type
                    )
                ),
              ]
            : submission?.accessUsers,
          inReview:
            submissionIds.includes(submission.id) && changeInReview ? false : submission?.inReview,
        })),
      rawSubmissionData
    )
  }
}

export const removePostBetweenAuthor = (
  submissionIds: string[],
  authors: { _id: string; type: 'admin' | 'customer' }[],
  mutateSubmissions: KeyedMutator<any>,
  rawSubmissionData: ISubmissionPaginate | ISubmissionPaginate[] | undefined,
  changeInReview?: boolean
) => {
  updateSubmissionInfo({
    submissionIds,
    access: {
      remove: authors,
    },
    ...(changeInReview && { inReview: false }),
  })
    .then(() => {
      toast.success('The post is now visible to everyone')
    })
    .catch(() => {
      toast.error('Could not change post privacy')
    })
  performSubmissionMutation(
    mutateSubmissions,
    (oldResults) =>
      oldResults.map((submission) => ({
        ...submission,
        // Remove the specified authors from accessUsers
        accessUsers: submissionIds.includes(submission.id)
          ? (submission?.accessUsers || []).filter(
              (user) =>
                !authors.some((author) => author._id === user._id && author.type === user.type)
            )
          : submission?.accessUsers,
      })),
    rawSubmissionData
  )
}

export const isPostPrivateWithUsers = (submission: ISubmission) => {
  return submission?.accessUsers && submission?.accessUsers?.length > 0
}

function objectIdToTimestamp(objectId: string) {
  if (objectId) {
    objectId = objectId.toString()
    return (
      parseInt(objectId.slice(0, 8), 16) * 1000 +
      Math.floor(parseInt(objectId.slice(-6), 16) / 16777.217)
    ) // convert 0x000000 ~ 0xffffff to 0 ~ 999
  } else {
    return 10000
  }
}

export const generateRoadmapFilter = (filters: ISubmissionFilters) => {
  const filtersHasBoardFilter = filters?.advancedFilters?.some(
    (filter) => filter?.type === 'b' && filter?.userGenerated
  )

  return objectToMongooseQueryString(
    filters.advancedFilters.filter((filter) =>
      filtersHasBoardFilter
        ? filter.type === 'b' && filter.operator === 'is not' && !filter.userGenerated
          ? false
          : true
        : true
    ),
    {
      sortBy: filters.sortBy,
      inReview: filters.inReview,
      includePinned: filters.includePinned,
    }
  ).backendQuery
}

export const generateRoadmapMainFilters = (isStatusFilter: boolean, inputValue?: string) => {
  if (!inputValue) return []
  return isStatusFilter
    ? [
        {
          type: 's',
          id: uuid(),
          operator: 'in',
          values: [inputValue],
        },
      ]
    : [
        {
          type: 'e',
          id: uuid(),
          operator: 'gte',
          values: [new Date(getStartDate(inputValue)).toISOString()],
        },
        {
          type: 'e',
          id: uuid(),
          operator: 'lte',
          values: [new Date(getEndDate(inputValue)).toISOString()],
        },
      ]
}

export function getETAQuarter(date: Date | string): number {
  const normalizedDate = retrieveDateWithoutTimezone(date)

  // Use the normalized date to get the month
  const month = normalizedDate.getMonth()
  return Math.floor(month / 3) + 1
}

export function retrieveDateWithoutTimezone(storedDateString: string | Date) {
  // Parse the stored ISO string
  const date = new Date(storedDateString)

  // Reconstruct the date in the user's local timezone
  const year = date.getUTCFullYear()
  const month = date.getUTCMonth()
  const day = date.getUTCDate()

  // Create a new Date object in the local timezone
  return new Date(year, month, day)
}

export function getDateInUtc(date: Date | string): string {
  let normalizedDate: Date

  if (typeof date === 'string') {
    normalizedDate = new Date(parseISO(date).toDateString())
  } else {
    normalizedDate = new Date(date.toDateString())
  }

  return normalizedDate.toISOString()
}

export const initIntercom = (
  user: IAdmin | ICustomer,
  org: IOrganization,
  boot: (props?: IntercomProps) => void,
  setShowChat: any
) => {
  if (isMember(user?.id, org)) {
    setShowChat(true)
    boot({
      name: user?.name,
      email: user?.email,
      userId: user?.id,
      createdAt: user?.id ? (objectIdToTimestamp(user?.id) / 1000).toString() : '',
      company: {
        plan: org?.plan,
        name: org?.displayName,
        companyId: org?.id,
        createdAt: (objectIdToTimestamp(org?.id) / 1000).toString(),
        customAttributes: {
          subscription_status: org?.subscriptionStatus,
          subscription_period: org?.subscriptionPeriod,

          board_url: 'https://' + org?.name + '.featurebase.app/',
          subdomain: org?.name,
        },
      },
      hideDefaultLauncher: true,
      verticalPadding: 70,
    })
  }
}

export const addYoutubeVideo = (editor: any, range?: any) => {
  if (!editor) return null

  const url = prompt('Enter Youtube/Loom/Descript Video URL')

  // Validate if is a valid URL
  if (!isURL(url || '', { require_protocol: true })) {
    alert('Please enter a full and valid URL for the video')
    return
  }

  if (url) {
    // Check if is youtube url
    let inputUrl = url
    // Add as embed
    const isLoom = inputUrl.includes('loom.com')
    const youtubeRegex = /^(https?\:\/\/)?(www\.youtube\.com\/watch\?v=|youtu\.be\/)([^&?\/\s]+).*/

    const isYoutube = youtubeRegex.test(url)

    if (isYoutube) {
      const match = url.match(youtubeRegex)
      const videoId = match?.[3] // Extract the video ID from the capturing group

      if (!videoId) return
      // Use the extracted video ID to form the embed URL
      inputUrl = `https://www.youtube.com/embed/${videoId}`
    } else if (isLoom) {
      inputUrl = inputUrl.replace('/share/', '/embed/')
    } else {
      // Assuming it's a Descript URL or another type with similar URL structure
      inputUrl = inputUrl.replace('/view/', '/embed/')
    }

    editor
      .chain()
      .focus()
      .setIframe({
        src: inputUrl,
        dataAttribute: {
          key: `data-${isLoom ? 'loom' : isYoutube ? 'youtube' : 'descript'}-video`,
          value: '',
        },
      })
      .run()

    if (range) {
      editor.chain().focus().deleteRange(range).run()
    }
    // Add new line after video
  }
}

export const useData = () => {
  const [currentNode, setCurrentNode] = useState<Node | null>(null)
  const [currentNodePos, setCurrentNodePos] = useState<number>(-1)

  const handleNodeChange = useCallback(
    (data: { node: Node | null; editor: Editor; pos: number }) => {
      if (data.node) {
        setCurrentNode(data.node)
      }

      setCurrentNodePos(data.pos)
    },
    [setCurrentNodePos, setCurrentNode]
  )

  return {
    currentNode,
    currentNodePos,
    setCurrentNode,
    setCurrentNodePos,
    handleNodeChange,
  }
}

export const updateStructure = (
  structure: IHelpCenterCollectionDocument['structure'] | undefined,
  newData: IHelpCenterArticleDocument | IHelpCenterCollectionDocument,
  deleteItem = false
): IHelpCenterCollectionDocument['structure'] | [] => {
  return structure
    ? structure
        ?.filter((item) => {
          if (deleteItem) {
            if (
              newData.type === 'article' &&
              item.type === 'article' &&
              item.articleId === newData.articleId
            ) {
              return false
            }
            if (
              newData.type === 'collection' &&
              item.type === 'collection' &&
              item.collectionId === newData.collectionId
            ) {
              return false
            }
          }
          return true
        })
        ?.map((item: any) => {
          if (
            newData.type === 'article' &&
            item.type === 'article' &&
            item.articleId === newData.articleId
          ) {
            return { ...item, ...newData }
          } else if (item.type === 'collection' && item?.structure) {
            if (newData.type === 'collection' && item.collectionId === newData.collectionId) {
              return { ...item, ...newData }
            }

            // Recurse into nested structures
            return {
              ...item,
              structure: updateStructure(item?.structure, newData, deleteItem),
            }
          }
          return item
        })
    : []
}

// export const iOS = /iPad|iPhone|iPod/.test(navigator?.platform)

export const createCollection = async (
  mutate: any,
  name: string,
  description: string,
  icon?: IHelpCenterIcon,
  parent?: string
) => {
  toast.promise(
    createHelpdocsCollection({
      name,
      description,
      icon,
      parentId: parent,
    }).then(() => {
      mutate()
    }),
    {
      loading: 'Creating collection...',
      success: 'Collection created successfully.',
      error: (e) => e.response?.data.error,
    }
  )
}

export const createArticle = (
  mutate: any,
  parentId?: string,
  changeRoute?: (id: string) => Promise<boolean>
) => {
  toast.promise(
    createHelpdocs({
      title: 'Untitled document',
      body: '',
      description: '',
      parentId,
    }).then((res) => {
      if (changeRoute && res.data.articleId) {
        changeRoute(res.data.articleId)
      }
      mutate()
    }),
    {
      loading: 'Creating article...',
      success: 'Article created successfully.',
      error: (e) => e.response?.data.error,
    }
  )
}

export const getSubmissionSimilarKey = (submission: ISubmission) => {
  return submission?.title?.slice(0, 60) + '-' + submission?.content?.slice(0, 60)
}

export const getHelpCenterPrefix = (org: IOrganization) => {
  return org.activeHelpCenter.externalDomain
    ? `${org.activeHelpCenter.externalDomain}`
    : `${getBoardUrl(org)}/help`
}

export const getLocaleFromSSR = async (context: GetServerSidePropsContext) => {
  if (context.locale && availableLocales.includes(context.locale)) {
    return context.locale
  }

  return 'en'
}

export const handleHelpCenterRedirect = (
  helpCenterdata: any,
  context: GetServerSidePropsContext,
  locale: string,
  collectionData?: IHelpCenterCollectionDocument,
  articleData?: IHelpCenterArticleDocument
) => {
  const isVisitingDefaultDirectly = context?.locale === 'default'

  const helpCenter: IHelpCenterDocument = helpCenterdata?.value?.data

  if (!collectionData && !articleData) {
    if (helpCenter?.defaultLocale !== 'en' && isVisitingDefaultDirectly) {
      return {
        redirect: {
          destination: `/${helpCenter.defaultLocale}/help`,
          permanent: false,
        },
      }
    }

    // Check that the locale is available in the help center
    if (!helpCenter?.availableLocales.includes(locale)) {
      console.log('redirecting to default locale', locale, helpCenter?.availableLocales)
      return {
        redirect: {
          destination: `/${helpCenter.defaultLocale}/help`,
          permanent: false,
        },
      }
    }
  }

  // Check for collection redirect
  if (collectionData && collectionData.locale !== locale) {
    const collectionSlug = collectionData.slug || collectionData.collectionId
    if (collectionSlug) {
      return {
        redirect: {
          destination: `/${collectionData.locale}/help/collections/${collectionSlug}`,
          permanent: false,
        },
      }
    }
  }

  // Check for article redirect
  if (articleData && articleData.locale !== locale) {
    const articleSlug = articleData.slug || articleData.articleId
    if (articleSlug) {
      return {
        redirect: {
          destination: `/${articleData.locale}/help/articles/${articleSlug}`,
          permanent: false,
        },
      }
    }
  }

  // Optimized code for checking if the router path matches the slug of collection or article
  try {
    // Extract the URL path and query parameters
    const [urlPath, queryParams] = context.resolvedUrl.split('?')
    // Split the path into segments, filtering out any empty strings to handle extra slashes
    const pathSegments = urlPath.split('/').filter((segment) => segment)
    // Get the last segment of the path
    const lastSegment = decodeURIComponent(
      pathSegments[pathSegments.length - 1] || ''
    ).toLowerCase()

    if (collectionData) {
      const collectionSlug = (collectionData.slug || collectionData.collectionId || '')
        .toString()
        .toLowerCase()

      if (collectionSlug && lastSegment !== collectionSlug) {
        return {
          redirect: {
            destination: `/${locale}/help/collections/${encodeURIComponent(collectionSlug)}${
              queryParams ? `?${queryParams}` : ''
            }`,
            permanent: false,
          },
        }
      }
    } else if (articleData) {
      const articleSlug = (articleData.slug || articleData.articleId || '').toString().toLowerCase()

      if (articleSlug && lastSegment !== articleSlug) {
        return {
          redirect: {
            destination: `/${locale}/help/articles/${encodeURIComponent(articleSlug)}${
              queryParams ? `?${queryParams}` : ''
            }`,
            permanent: false,
          },
        }
      }
    }
  } catch (error) {
    // Log the error and proceed without redirecting
    console.error('Error in handleHelpCenterRedirect:', error)
  }

  // If no redirect is needed, return null or undefined
  return null
}
