import { useUser } from '@/data/user'
import Navigation from './DevDocsNavigation'
import { useEffect, useState } from 'react'
import { meilisearchClientAtom, showIntercomChatAtom } from '@/atoms/orgAtom'
import { useAtom } from 'jotai'
import DocsMinimap from './docs/DocsMinimap'
import CustomThemeHandler from './CustomThemeHandler'
import { IHelpCenterArticleDocument, IHelpCenterCollectionDocument } from '@/interfaces/IHelpCenter'
import { cn } from '@/lib'
import { getHelpCenterSearchKey } from 'network/lib/organization'
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch'
import SearchArticles from './docs/SearchArticles'
import { showArticleSearchAtom } from '@/atoms/docsAtom'
import FBAIBot from './FBAIBot'
import { useCurrentOrganization, useUserOrganizations } from '@/data/organization'
import SelfServeDropdown from './SelfServeDropdown'
import { initIntercom } from '@/lib/utils'
import { useIntercom } from 'react-use-intercom'
import { useTheme } from 'next-themes'
import Script from 'next/script'
import PrivateOrg from './PrivateOrg'
import PublicBoardAuth from './PublicBoardAuth'
import { authenitcateModalAtom } from '@/atoms/authAtom'
import { CogIcon } from '@heroicons/react/solid'
import { isMember } from '@/lib/acl'
import Link from './CustomLink'

const DevDocsLayout = ({
  children,
  hideMinimap,
  docsData,
  collection,
  wide,
}: {
  children: React.ReactNode
  hideMinimap?: boolean
  docsData?: IHelpCenterArticleDocument
  collection?: IHelpCenterCollectionDocument
  wide?: boolean
}) => {
  const [meilisearchClient, setMeilisearchClient] = useAtom(meilisearchClientAtom)
  const { user } = useUser()
  const { org, mutateCurrentOrg } = useCurrentOrganization()
  const { boot } = useIntercom()
  const [showChat, setShowChat] = useAtom(showIntercomChatAtom)
  const { orgResults } = useUserOrganizations(org?.name !== 'feedback')
  const { theme, systemTheme } = useTheme()
  const activeTheme = theme !== 'dark' && theme !== 'light' ? systemTheme : theme
  const [authenitcateModal, setAuthenitacteModal] = useAtom(authenitcateModalAtom)

  useEffect(() => {
    getHelpCenterSearchKey()
      .then((res) => {
        if (res?.data?.apiKey) {
          setMeilisearchClient(
            instantMeiliSearch(process.env.NEXT_PUBLIC_MEILISEARCH_HOST as string, res.data.apiKey)
              .searchClient
          )
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }, [user])

  const [searchOpen, setSearchOpen] = useAtom(showArticleSearchAtom)

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
        event.preventDefault()
        setSearchOpen(true)
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  useEffect(() => {
    if (org && user && org.name === 'feedback') {
      initIntercom(user, org, boot, setShowChat)
      try {
        window?.Featurebase(
          'initialize_feedback_widget',
          {
            organization: 'feedback',
            theme: activeTheme,
            userHash: user.featurebaseUserHash,
          },
          (err: any, data: any) => {
            if (err) {
              console.log(err)
            } else {
              console.log(data)
            }
          }
        )
      } catch (err) {
        console.log(err)
      }
    }
  }, [user, org])

  return (
    <CustomThemeHandler maxSaturation={true}>
      <SearchArticles isOpen={searchOpen} setOpen={setSearchOpen} />
      <PrivateOrg />
      <PublicBoardAuth
        isOpen={authenitcateModal}
        setIsOpen={setAuthenitacteModal}
        callback={() => {
          mutateCurrentOrg()
          // Refresh the page
          window.location.reload()
        }}
        widget={true}
      />
      {org.name === 'feedback' && (
        <Script
          id="featurebase-embedding"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
               window.FEATUREBASE_ENV="${
                 process.env.NEXT_PUBLIC_ENVIRONMENT === 'development'
                   ? 'development'
                   : 'production'
               }";
              const SDK_URL = "${
                process.env.NEXT_PUBLIC_ENVIRONMENT === 'development'
                  ? 'https://sdk.fbasedev.com/dist/sdk.js'
                  : 'https://do.featurebase.app/js/sdk.js'
              }";

              !(function (e, t) {
                const a = "featurebase-sdk";
                function n() {
                  if (!t.getElementById(a)) {
                    var e = t.createElement("script");
                    (e.id = a),
                      (e.src = SDK_URL),
                      t
                        .getElementsByTagName("script")[0]
                        .parentNode.insertBefore(e, t.getElementsByTagName("script")[0]);
                  }
                }
                "function" != typeof e.Featurebase &&
                  (e.Featurebase = function () {
                    (e.Featurebase.q = e.Featurebase.q || []).push(arguments);
                  }),
                  "complete" === t.readyState || "interactive" === t.readyState
                    ? n()
                    : t.addEventListener("DOMContentLoaded", n);
              })(window, document);
    `,
          }}
        />
      )}
      {/* <style>
        {`
          
        
              .dark {
                // --primary: 5.696202531645571 65.28925619834712% 47.45098039215686%;
                // --primary-foreground: 0 0% 100%;
                // --accent: 7.719298245614033 94.47513812154696% 64.50980392156862%;
                // --accent-foreground: 14.621848739495803 100% 76.66666666666666%;
                // --modified-primary: 3.9215686274509807 71.83098591549295% 41.76470588235294%;
                
                // --portal-widget-dark-background: 3.9215686274509807 71.83098591549295% 41.76470588235294%;
                // --portal-widget-lighter-background: 0 100% 13.725490196078432%;
              --background: 220 10.344827586206897% 9.372549019607844%;--foreground: 229.090909090909 7.692307692307704% 71.96078431372548%;--background-accent: 225.71428571428572 10.344827586206893% 39.80392156862745%;--muted: 225 10% 15.686274509803921%;--muted-foreground: 229.090909090909 7.692307692307704% 71.96078431372548%;--dark-accent: 223.63636363636363 9.909909909909913% 21.764705882352942%;--dark-accent-foreground: 228 7.936507936507908% 87.6470588235294%;--popover: 230 12.5% 9.411764705882353%;--popover-foreground: 229.090909090909 7.692307692307704% 71.96078431372548%;--border: 225 8.51063829787234% 18.43137254901961%;--input: 225 10% 15.686274509803921%;--card: 220.00000000000003 9.375000000000005% 12.549019607843137%;--card-foreground: 229.090909090909 7.692307692307704% 71.96078431372548%;--secondary: 225 10% 15.686274509803921%;--secondary-foreground: 229.090909090909 7.692307692307704% 71.96078431372548%;--destructive: 4.736842105263158 37.99999999999999% 19.607843137254903%;--destructive-foreground: 210 16.666666666666625% 97.6470588235294%;

            }
            
          `}
      </style> */}

      {/* <FBAIBot customOrg={org} />
      <SelfServeDropdown customOrg={org} /> */}
      {/* <PublicBoardAuth
        isOpen={authenitcateModal}
        setIsOpen={setAuthenitacteModal}
        callback={() => {
          mutateOrganizations()
        }}
        widget={true}
      /> */}
      <main className="min-h-[100dvh] bg-white dark:bg-transparent lg:flex-col">
        <div className="xl:pl-[279px] relative w-full min-h-full">
          <Navigation />

          <div className={`flex gap-10 px-4 sm:px-12 py-10 lg:py-16 relative w-full`}>
            <div className="absolute inset-x-0 top-0 w-full h-32 pointer-events-none bg-gradient-to-b from-gray-50 dark:from-secondary/30 to-transparent"></div>
            <article
              className={cn(
                'w-full max-w-[720px] z-10 flex-shrink min-w-0 mt-0 mb-24 mx-auto',
                wide && 'max-w-[870px]'
              )}
            >
              {children}
            </article>
            {!hideMinimap && <DocsMinimap docsData={docsData} />}
          </div>
        </div>
      </main>
      {org.name === 'feedback' && orgResults && orgResults?.results?.[0] && (
        <>
          <SelfServeDropdown />
          <FBAIBot customOrg={orgResults && orgResults?.results?.[0]} />
        </>
      )}
      {isMember(user?.id, org) && (
        <div className="fixed inset-x-0 bottom-6 flex justify-center pointer-events-none z-[9999]">
          <div className="relative pointer-events-auto">
            <Link href="/dashboard">
              <button className="inline-flex items-center dashboard-secondary rounded-full py-2 px-4 relative backdrop-blur-md shadow-lg hover:shadow-xl transition-shadow duration-300">
                <CogIcon className="secondary-svg mr-1.5" /> Go to Dashboard
              </button>
            </Link>
          </div>
        </div>
      )}
    </CustomThemeHandler>
  )
}

DevDocsLayout.theme = 'light'

export default DevDocsLayout
